import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery, Link } from "gatsby";
import { useTranslation } from "react-i18next";
import "../css/global.less";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import logo from "../img/KXP_logo_White.svg";

import "../i18n";
import Footer from "../components/Footer";
import MainMenu from "../components/layout/menu/main-menu";

function Blog() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const query = useStaticQuery(graphql`
    {
      allWpPost {
        totalCount
        nodes {
          title
          id
          slug
          link
          excerpt
          internal {
            description
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  `);

  const hasImage = (node) => {
    if (node.featuredImage) {
      return true;
    }
    return false;
  };

  const siteUrl = "https://app-kxp-www-prod-02.azurewebsites.net/";

  const sliderPosts = query.allWpPost.nodes.slice(-3);
  const cleanParagraphTag = (text) => {
    const cleanText = text.replace(/<p>/g, "");
    const limitText = cleanText.substring(0, 200);
    return limitText.replace(/<\/p>/g, "");
  };

  const slideImages = sliderPosts.map((post) => ({
    url: hasImage(post)
      ? `${siteUrl}/${post.featuredImage.node.mediaItemUrl}`
      : "",
    postname: post.title,
    postexcerpt: cleanParagraphTag(post.excerpt),
    postlink: post.slug,
  }));

  return (
    <>
      <Helmet>
        <title>Blog da KXP</title>
        <meta name="description" content={t("description")} />
      </Helmet>
      <header>
        <Link to="/">
          <img className="logo" src={logo} alt="KXP technology consulting" />
        </Link>
        <MainMenu />
      </header>
      <div>
        <Slide>
          {slideImages.map((slideImage) => (
            <div key={slideImage.id}>
              <div
                className="slider min-h-screen"
                style={{ backgroundImage: `url(${slideImage.url})` }}
              >
                <div className="slider_info_container">
                  <div className="slider_info bg-zinc-950 p-6 bg-opacity-75">
                    <h2 className="accent-color text-3xl font-bold">
                      {slideImage.postname}
                    </h2>
                    <p className="white">{slideImage.postexcerpt}</p>
                    <a href={slideImage.postlink} className="white_button">
                      {t("blog.readMore")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slide>
      </div>

      <div className="flex flex-col w-full h-full gap-4 p-6">
        <h2 className="accent-color text-3xl font-bold">
          {t("blog.lastPosts")}
        </h2>
        <div className="flex flex-row flex-wrap gap-4">
          {query.allWpPost.nodes.map((post) => (
            <div
              className="post_img_block"
              key={post.id}
              style={
                hasImage(post)
                  ? {
                      backgroundImage: `url(${siteUrl}${post.featuredImage.node.mediaItemUrl})`,
                    }
                  : {}
              }
            >
              <div className="post_info_block">
                <h3 className="accent-color">{post.title}</h3>
                <p className="white">{cleanParagraphTag(post.excerpt)}</p>
                <Link to={post.slug} className="white_button">
                  {t("blog.readMore")}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blog;
